.NavItem {
  margin: 13px 0px;
}

.NavItem[aria-selected="true"] {
  text-decoration: underline;
  cursor: default;
}

.NavItem[aria-selected="false"] {
  cursor: pointer;
}

.NavItem:hover {
  text-decoration: underline;
}

@media (max-width: 767.98px) {
  .NavItem {
    margin: 0px;
  }
}
