.Nav {
  font-weight: 700;
}

@media (max-width: 767.98px) {
  .Nav {
    display: flex;
    justify-content: space-between;
    width: 368px;

    margin-bottom: 30px;
  }
}

@media (max-width: 575.98px) {
  .Nav {
    width: 281px;

    margin-bottom: 20px;
  }
}
