:root {
  --color-0: #fbf5ef;
  --color-1: #d1a0ed;
  --color-2: #f2ebab;
  --color-3: #272744;
}

.color-white {
  color: var(--color-0);
}

.color-purple {
  color: var(--color-1);
}

.color-yellow {
  color: var(--color-2);
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
