.App {
  text-align: center;
}

.App-body {
  background-color: var(--color-3);
  height: 100vh; /* Fallback for browsers that do not support Custom Properties */
  height: calc(var(--vh, 1vh) * 100);

  display: grid;
  grid-auto-flow: row;
  grid-auto-rows: 1fr;
  align-items: center;
  justify-items: center;
}

.App-title {
  font-family: Montserrat;
  font-size: 128px;
  font-style: normal;
  font-weight: 500;
  line-height: 156px;
  letter-spacing: 0em;
  text-align: center;
}

.App-main {    
  font-family: Dosis;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 30px;
  letter-spacing: 0em;
  text-align: left;

  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 654px;
}

@media (max-width: 767.98px) {
  .App-main {
    flex-direction: column;
    justify-content: flex-start;
    height: 100%;
    width: 368px;

    font-size: 18px;
    line-height: 23px;
  }

  .App-title {
    font-size: 72px;
    line-height: 88px;
  }
}

@media (max-width: 575.98px) {
  .App-main {
    width: 281px;

    font-size: 14px;
    line-height: 18px;
  }

  .App-title {
    font-size: 55px;
    line-height: 67px;
  }
}
