.ContentWrapper {
  width: 417px;
}

.ContentWrapper div {
  margin: 20px 0px;
}

.ContentWrapper div:first-child {
  margin-top: 0px;
}

.ContentWrapper div:last-child {
  margin-bottom: 0px;
}

.ContentWrapper a {
  color: var(--color-2);
  text-decoration: none;
}

.ContentWrapper a:hover {
  text-decoration: underline;
}

.ContentWrapper b {
  color: var(--color-1);
  font-weight: normal;
}

@media (max-width: 767.98px) {
  .ContentWrapper {
    width: 368px;
  }
}

@media (max-width: 575.98px) {
  .ContentWrapper {
    width: 281px;
  }
}
