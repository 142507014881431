.SocialIcons {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 116px;
  height: 24px;
}

.SocialIcons-icon {
  width: 24px;
  height: 24px;
}

@media (max-width: 575.98px) {
  .SocialIcons {
    width: 86px;
    height: 18px;
  }

  .SocialIcons-icon {
    width: 18px;
    height: 18px;
  }
}

@media (max-width: 767.98px) {
  .SocialIcons {
    width: 116px;
    height: 24px;
  }

  .SocialIcons-icon {
    width: 24px;
    height: 24px;
  }
}
